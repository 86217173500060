import React from "react"
import { Link, graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import projectStyle from './projects.module.scss'
import Head from '../components/head'

const BlogPage = () => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulBlog (
          sort: {
            fields:date,
            order:DESC
          }
        ) {
          edges {
            node {
              title
              slug
              date(formatString:"MMMM Do, YYYY")
            }
          }
        }
      }
    `)

    var l = true;
  var side = "";


    return (
        <div>
            <Layout>
              <Head title="Blog" />
                <h1>Blog</h1>
                <ol className={projectStyle.posts}>
                    {data.allContentfulBlog.edges.map((edge) => {
                      l = !l;
            if(l) {
              side = "slide-left"
            } else {
              side = "slide-right"
            }
                        return (
                            <li className={projectStyle.post} data-sal={side} data-sal-delay="0"
                            data-sal-easing="ease" data-sal-duration="1200">
                                <Link to={`/blog/${edge.node.slug}`}>
                                    <h2>
                                        {edge.node.title}
                                    </h2>
                                    <p>
                                    {edge.node.date}
                                    </p>
                                </Link>
                                
                            </li>
                        )
                    })}
                </ol>
            </Layout>
        </div>
    )
}

export default BlogPage